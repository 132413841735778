import React from 'react'

function Project (props) {

  return (
    <div className='bg-color-background-default container rounded-2xl'>
      <div className='bg-gray-300 mx-3 my-3 m-auto rounded-xl'>
        <div className = "container flex flex-wrap">
          <p style={{ fontSize: 50 }} className=' text-white bg-color-background-default container text-center'>
          #{props.rank + 1} 
          </p>
          <p dangerouslySetInnerHTML={{__html: props.project.title}} className='pb-6 justify-center my-auto container text-center content-center text-3xl text-white bg-color-background-default'>
          </p>
        </div>
        <iframe
          className='w-full aspect-square'
          src={'https://www.youtube.com/embed/' + props.project.youtube}
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
        <p dangerouslySetInnerHTML={{__html: props.project.body}} className='bg-gray-300 text-center p-4'>
        </p>
      </div>
    </div>
  )
}

export default Project
