import React from 'react'
//import clipboard from './media/clipboard.png'

function Clipboardcopy (props) {
/*
  function copyText () {
    // Copy the text inside the text field
    navigator.clipboard.writeText(props.toClipText)

    // Alert the copied text
    console.log('moo')
    alert('Copied the text: ' + props.toClipText)
  }
  */
  return (
    <>
      <div className='grid grid-cols-1'>
        <div className='bg-slate-500 p-2 rounded px-8 grid grid-cols-1 content-center'>
            {props.toClipText}
        </div>
        {/*
        <button  className='bg-green-100 w-12'>
        <img  onClick={copyText} src={clipboard} className='' alt='ERROR' height={50} width={50}
        />
      </button>
  */}

      </div>
    </>
  )
}

export default Clipboardcopy
